import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import { FaBars, FaTimes } from "react-icons/fa";
import { SliderButton } from '@typeform/embed-react';
import "./Navbar.css";
import ntlogo from "../images/ntlogoV2.png";

function Navbar() {
	const [click, setClick] = useState(false);
	const [showForm, setShowForm] = useState(false);
	const handleClick = () => setClick(!click);
	const closeMenu = () => setClick(false);

	useEffect(() => {
		const handleOutsideClick = (event) => {
			if (click && navRef.current && !navRef.current.contains(event.target)) {
				setClick(false);
			}
		};
		document.addEventListener("mousedown", handleOutsideClick);
		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [click]);

	const navRef = React.useRef();

	return (
		<>
			<nav className="fixed top-0 z-10 w-full bg-black px-4 h-24" ref={navRef}>
				<div className="flex justify-between h-full items-center">
					<a href="/" className="bg-white rounded ml-4">
						<img src={ntlogo} alt="Logo" className="w-16 h-16 select-none"/>
					</a>
					<div className="hamburger" onClick={handleClick}>
						{click ? (
							<FaTimes size={30} style={{ color: "#ffffff" }} />
						) : (
							<FaBars size={30} style={{ color: "#ffffff" }} />
						)}
					</div>

					<ul className={click ? "navMenu active" : "navMenu flex items-center"}>
						<li>
							<Link
								to="services"
								spy={true}
								smooth={true}
								offset={-150}
								duration={1500}
								onClick={closeMenu}
								className="navItem"
							>
								Services
							</Link>
						</li>
						<div className="seperatorLines"></div>
						<li>
							<Link
								to="about"
								spy={true}
								smooth={true}
								offset={-150}
								duration={1500}
								onClick={closeMenu}
								className="navItem"
							>
								About
							</Link>
						</li>
						<div className="seperatorLines"></div>
						<li>
							<Link
								to="contact"
								spy={true}
								smooth={true}
								offset={-150}
								duration={2000}
								onClick={closeMenu}
								className="navItem"
							>
								Contact
							</Link>
						</li>
						<div className="seperatorLines"></div>
						<li>
							<SliderButton
								id="oclOKrVB"
								onClick={() => { closeMenu(); }}
								spy={true}
								smooth={true}
								offset={-150}
								duration={2000}
								className="bg-purple-500 p-3 rounded-md hover:bg-purple-700 text-white cursor-pointer transition-all duration-300 font-medium"
							>
							Get a quote
							</SliderButton>
						</li>
					</ul>
				</div>
			</nav>


		</>
	);
}

export default Navbar;
