import React from "react";
import { Link } from "react-scroll";
import { useInView } from "react-intersection-observer";
import { TypeAnimation } from "react-type-animation";
import { SliderButton } from '@typeform/embed-react';
import Navbar from "./Navbar";
import "../components/Hero.css";
import "../components/Footer.css";
import "../components/HeroTitleEffect.css";
import logo from "../images/ntlogoV2.png";
import sd from "../vectors/sd.svg";
import mb from "../vectors/mb.svg";
import tg from "../vectors/tg.svg";
import bc from "../vectors/bc.svg";
import fi from "../vectors/fiverr-icon.svg";
import arrow from "../vectors/line-angle-down-icon.svg";

const Hero = () => {
	const { ref: servicesHeader, inView: isVisible } = useInView({
		triggerOnce: true,
		threshold: 0.1,
	});

	return (
		<div className="hero from-black from-10% via-[#301955] via-20% to-[#16011c] bg-gradient-to-b">
			<div className="main-content" id="main-content">
				<h1 className="md:!text-7xl !text-6xl max-w-3xl font-black text-center m-4">
					Innovating the future, one idea at a time.
					<div className="aurora">
						<div class="aurora__item"></div>
						<div class="aurora__item"></div>
						<div class="aurora__item"></div>
						<div class="aurora__item"></div>
					</div>
				</h1>

				<p>
					Software and hardware solutions - leveraging cutting edge technology
					to transform your ideas into reality.
				</p>
				{/* ARROW DOWN BUTTON */}
				<Link
					to="services"
					spy={true}
					smooth={true}
					offset={-150}
					duration={1500}
				>
					<p alt="discover" className="!font-[550] !text-lg discover">
						Discover our services
					</p>
				</Link>
			</div>
			<div className="services-content" ref={servicesHeader}>
				{/* <div className="bg">
					<div className="bg-text"> */}
				<p id="services">
					{isVisible ? (
						<TypeAnimation
							sequence={[
								"Our services are tailored to your needs.",
								500,
								"Our services are tailored to your needs. Whether its a Web3 project,",
								500,
								"Our services are tailored to your needs. Whether its a Web3 project, a Prototype",
								500,
								"Our services are tailored to your needs. Whether its a Web3 project, a Prototype or a full-fledged web app...",
							]}
							wrapper="span"
							speed={50}
							deletionSpeed={60}
							preRenderFirstString={false}
						/>
					) : (
						""
					)}
					<div className="headerSeperator"></div>
				</p>
				{/* </div>
				</div> */}

				<img className="svg-format center-svg" src={sd} alt="SD" />
				<h2 className="text-center">Web Development</h2>
				<p>
					In the realm of web development, <strong>nulltech</strong> stands as a beacon of innovation and functionality.
					Our team is dedicated to developing cutting-edge web solutions that are not just visually appealing, but also highly functional and user-friendly. 
					<br /><br />
					We understand that a website is more than just an online presence; it's a vital tool for business growth and engagement. 
					Our approach is to blend modern design with practical usability, ensuring your web platform is accessible, responsive, and tailored to meet the unique needs of your business.
					<br /><br />
					Whether it's an interactive e-commerce site, a dynamic content management system, or a custom web application, NullTech's expertise in web development is about bringing your vision to life in the digital world. Let us help you navigate the complexities of the web with solutions that speak volumes about your brand's innovation and forward-thinking approach.
				</p>
				<img className="svg-format center-svg" src={bc} alt="BC" />
				<h3 className="text-center">WEB3 & Blockchain</h3>
				<p>
					At <strong>nulltech</strong>, we specialise in Web3 and Blockchain development, focusing on creating innovative solutions for businesses & projects.
					Our team is dedicated to building the next generation of digital platforms that are secure, transparent, and user-friendly. 
					<br /><br />
					We help businesses step into the world of blockchain, enabling them to operate more efficiently and securely in a connected digital ecosystem. 
					With our expertise and ongoing support, we guide our clients through every step of the process, ensuring their success in the rapidly evolving digital landscape. 
					Trust NullTech to bring cutting-edge technology to your project, one step at a time.
				</p>
				<img className="svg-format center-svg" src={tg} alt="TG" />
				<h4 className="text-center">ChatBots Integrations</h4>
				<p>
					<strong>nulltech</strong> began its tech journey by establishing a strong presence on chatbot development, setting a foundation for our innovative approach. 
					We specialise in creating chatbots that enhance business communication and user engagement. 
					These chatbots are not just technically adept but also user-friendly, ensuring seamless interactions. 
					<br /><br />
					Our expertise in this niche symbolises our commitment to delivering practical, efficient digital solutions tailored to your business needs. 
					Partner with nulltech to harness the power of chatbots, where our journey in tech excellence began.
				</p>
				<img className="svg-format center-svg" src={mb} alt="MB" />
				<h5 className="text-center">R&D and Prototyping</h5>
				<p>
					At <strong>nulltech</strong>, our prototyping service is dedicated to turning your hardware concepts into fully realised prototypes.

					We specialise in designing and building sophisticated hardware prototypes from the ground up. 
					<br /><br />
					Our process encompasses everything from crafting the intricate circuitry and physical design to developing user-friendly applications for system control.

					Our team is adept at creating prototypes that are not only technically sound but also practical for real-world application. Whether it’s an advanced IoT device or a unique electronic gadget, nulltech is committed to transforming your ideas into tangible, functional hardware prototypes, ready for testing and implementation.
				</p>
				
			</div>
			<div className="aboutus-content">
				<div className="bg">
					<div className="bg-text">
						<h6 id="about">About us</h6>
						<div>
							<p>
								We are a dedicated team of developers driven by a profound enthusiasm for technology and innovation.
								Our unwavering commitment lies in continuously seeking fresh challenges and delivering unparalleled excellence to our esteemed clientele.
								Our cohesive team comprises individuals endowed with a diverse spectrum of skills and capabilities,
								enabling us to adeptly address and conquer a wide array of projects that cross our path.
							</p>
						</div>
					</div>
				</div>
			</div>
			<div className="contactus-content">
				<div className="contact-section">
					<h7 id="contact">
						Get in touch<div className="seperatorLine"></div>
					</h7>
					<h8>For general business inquries:</h8>
					<a className="mailto-button" href="mailto:info@nulltech.it">
						info@nulltech.it
					</a>
					<div className="flex w-full md:gap-12 gap-4">
						<div className="flex flex-col">
							<h className="font-medium">Join us on Telegram</h>
							<a
								href="https://t.me/gnulltech"
								target="_blank"
								rel="noreferrer"
							>
								<img
									className="!h-14 !w-14 svg-format contact-section-svg"
									src={tg}
									alt="Telegram Logo"
								/>
							</a>
						</div>
						<div className="flex flex-col">
							<h>Check out our Fiverr</h>
							<a
								href="https://www.fiverr.com/nulltesting"
								target="_blank"
								rel="noreferrer"
							>
								<img className="!h-14 !w-14 contact-section-svg" src={fi} alt="Fiverr Logo" />
							</a>
						</div>
					</div>

					<div className="flex flex-col mt-4 items-center gap-3">
						<p className="font-medium">Or request an estimate for your project</p>
						<SliderButton
							id="oclOKrVB"
							spy={true}
							smooth={true}
							offset={-150}
							duration={2000}
							className="border-purple-500 border p-3 w-36 rounded-md hover:bg-purple-700 text-white cursor-pointer transition-all duration-300 font-medium"
						>
						Get a quote
						</SliderButton>
					</div>
				</div>
			</div>
			<div className="footer">
				<img src={logo} alt="logo" className="footer-logo" />
				<p className="footer-text">
					Providing reliable tech solutions since 2020.
				</p>
				<p className="footer-text">© 2024 nulltech OÜ. All rights reserved.</p>
				<a href="/files/NT_Terms_and_Conditions2024.pdf" target="_blank" rel="noopener noreferrer">Terms and Conditions Apply.</a>
			</div>
			
		</div>
	);
};

export default Hero;
