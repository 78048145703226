import "./App.css";
import { Analytics } from "@vercel/analytics/react";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";

function App() {
	return (
		<div className="App">
			<Analytics />
			<Navbar />
			<Hero />
		</div>
	);
}
export default App;
